import React from "react"
import Layout from "../layouts/es"
import { HelpCircle } from "react-feather"
import SEO from "../components/seo"

const LegalityPage = (props) => {
    return <Layout location={props.location}>
        <div>
            <SEO title="Firma electrónica con seguridad jurídica eIDAS"
                 description="Firma electrónica según normativa eIDAS. Sellado de tiempo y documento probatorio. Firma LTV." />

            <section className="py-5 header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="position-absolute top-right text-primary-dark mt-n12 decor2">
                                <img src={"/img/ic_packages_1.svg"} alt="" />
                            </div>
                            <h3 align="center" className="spacing">Legalidad</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 ahorro">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 mt-5">
                            <h3 className="font-weight-bold spacing">
                                ¿Qué es la firma electrónica?
                            </h3>
                            <p className="font-size-md text-black-80 spacing mt-5 mb-2">
                                La firma electrónica es una indicación en formato electrónico de la intención de una o varias personas de aceptar el contenido de un documento o un
                                conjunto de datos con los que se relaciona la firma, creando así un concepto legal que captura la intención del signatario de quedar obligado por
                                los
                                términos del documento firmado.
                            </p>
                            <p className="font-size-md text-black-80 spacing mt-2 mb-4">
                                La firma electrónica aplica mecanismos criptográficos al contenido de un documento con el objetivo de demostrar al receptor del mensaje que el
                                emisor
                                del mensaje es real, que éste no puede negar que envió el mensaje y que el mensaje no ha sido alterado desde su emisión.
                            </p>
                        </div>

                        <div className="col-xl-1" />

                        <div className="col-xl-6">
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor4">
                                <img src={"/img/home_signature_2.svg"} alt="" />
                            </div>

                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor3">
                                <img src={"/img/home_signature_2.svg"} alt="" />
                            </div>

                            <img src={"/img/sign.jpg"} width="100%" className="img-fluid mt-2 mb-5 img-prod" alt="" />
                        </div>
                    </div>

                    <br />
                </div>

                <div className="svg-border-angled text-light">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="#fff">
                        <polygon points="0,100 100,0 100,100" />
                    </svg>
                </div>

                <br /><br />
            </section>

            <section className="py-5 kyc">
                <div className="container">
                    <div className="row">
                        <div align="center" className="col-xl-6">
                            <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">
                                <div className="device device-iphonex">
                                    <img src={"/img/signature-m1.png"} className="device-screen" alt="..." />
                                    <img src={"/img/iphonex.svg"} className="img-fluid ip" alt="..." />
                                </div>

                                <div className="device device-iphonex">
                                    <img src={"/img/signature-m2.png"} className="device-screen" alt="..." />
                                    <img src={"/img/iphonex.svg"} className="img-fluid ip" alt="..." />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <h3 className="font-weight-bold spacing ">
                                La firma electrónica avanzada de Tecalis Signature
                            </h3>
                            <p className="font-size-md text-black-80 spacing mt-4 mb-4">
                                La firma electrónica avanzada tiene un <span className="font-weight-bold text-tecalis">nivel de seguridad superior al de la firma simple</span>. El
                                sistema de creación de firma electrónica avanzada que ofrece Tecalis Signature está desarrollado de forma que las firmas realizadas cumplan con cada
                                uno
                                de estos 4 requisitos legales para considerarse como tal:
                            </p>

                            <p className="font-size-md text-black-80 spacing mt-2 mb-4">
                                👉 Para asegurar que la firma sólo puede asociarse a un único firmante, en el momento de la firma recopilamos un gran volumen de información y
                                utilizamos una <b>autoridad de sellado de tiempo para garantizar la integridad de la misma.</b>
                            </p>

                            <p className="font-size-md text-black-80 spacing mt-2 mb-4">
                                👉 Para identificar al firmante, se geolocaliza con exactitud el lugar en el que se ha realizado la firma, registramos las direcciones de origen y
                                destino de la solicitud y la hora de la firma, y <b>capturamos datos biométricos del grafo</b> a partir de la presión ejercida en el dispositivo de
                                firma (en aquellos dispositivos que lo permite). De este modo, podemos poner esta información a disposición de un grafólogo en caso de disputa para
                                que pueda realizar su <b>análisis de firmas.</b>
                            </p>

                            <p className="font-size-md text-black-80 spacing mt-2 mb-4">
                                👉 El pleno control de la firma electrónica avanzada por parte del firmante está también garantizado por la versatilidad de nuestra plataforma, que
                                <b>permite firmar documentos fácilmente desde cualquier dispositivo.</b> Sólo es necesario usar un dedo, un puntero o el ratón, y por lo tanto no
                                interviene ninguna otra persona que pueda falsear el proceso
                            </p>

                            <p className="font-size-md text-black-80 spacing mt-2 mb-4">
                                👉 Impedimos cualquier cambio a posteriori en la firma, garantizando a solicitante y firmante que la firma obtenida no se puede modificar tras su
                                realización. Para ello, <b>ciframos la documentación generada</b> conforme a unos estándares de encriptación que garantizan su integridad al 100%.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="f1 mb-6 border-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9 text-center">
                            <br />
                            <h5><span className="badge rounded-pill bg-info mt-5 py-2 pr-3 pl-3 spacing">¿Cómo se verifica la identidad del firmante?</span></h5>
                            <br /><br />

                            <h2 className="font-weight-bold spacing" data-aos="fade-up">
                                La principal aportación de la tecnología en este ámbito es la fiabilidad en la medición de los
                                elementos objetivables.
                            </h2>

                            <br /><br />
                        </div>

                        <div className="col-12 col-md-10 col-lg-9" data-aos="fade-up">
                            <p className="font-size-md text-black-80 spacing">
                                La ventaja que ofrece nuestra solución es que incluye tecnología de captura de datos biométricos del
                                firmante, lo que nos permite obtener datos del grafo que podemos poner a disposición del perito
                                calígrafo en caso de que sea requerido por un tribunal. La información biométrica que recogemos
                                incluye los puntos que conforman el grafo, su posición, la velocidad y aceleración con la que se
                                realiza y, en algunos dispositivos que ya lo permiten, también capturamos la presión con la que se
                                realiza la firma.
                            </p>
                            <p className="font-size-md text-black-80 spacing">
                                En caso de que una firma realizada con nuestra solución de firma electrónica avanzada fuese dubitada, esta información biométrica podría
                                proporcionarse
                                en un proceso de instrucción o como evidencia en los tribunales. Además de poder presentar un informe pericial, con la fiabilidad que confiere el
                                hecho
                                los datos biométricos que recogemos son imposibles de falsificar, quienes utilizan nuestra solución de firma electrónica avanzada disponen además de
                                una
                                serie de evidencias electrónicas recogidas durante el proceso de firma, que ofrecen información sobre la realización de la firma, como por ejemplo
                                la
                                localización del lugar donde se realizó la firma, la fecha y la hora o la dirección IP, entre otras.
                            </p>
                            <p className="font-size-md text-black-80 spacing">Toda esta información se incluye en un documento
                                probatorio (audit trail) que también puede presentarse ante un tribunal como información
                                complementaria para verificar la identidad del firmante en un proceso judicial.
                            </p>

                            <br /><br />

                            <div className="accordion mb-5" id="accordionExample">
                                <div className="card">
                                    <div className="card-header-1" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                                    <h5>El documento probatorio</h5>
                                                    <small className="text-muted">
                                                        <HelpCircle className="icon2" />
                                                    </small>
                                                </div>
                                            </button>
                                        </h2>
                                    </div>

                                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body-1 spacing check-list">
                                            El documento probatorio es un documento en el que quedan registradas todas las evidencias electrónicas generadas durante el proceso de
                                            firma. Estas evidencias son las que aseguran la integridad de la información en todo momento, evitando cualquier tipo de manipulación.
                                            Por
                                            ello, el documento probatorio es admisible a juicio como prueba legal válida ante cualquier tribunal de justicia.<br /><br />
                                            Nuestro documento probatorio está diseñado para facilitar la lectura y la localización de las diferentes evidencias electrónicas que
                                            recogemos durante el proceso de firma, y las clasificamos del siguiente modo:<br /><br />
                                            En el apartado “Información del envío” se detallan las siguientes evidencias electrónicas:<br /><br />

                                            <ul className="spacing mb-5">
                                                <li>Nombre y correo electrónico del emisor</li>
                                                <li>Nombre y correo electrónico del firmante</li>
                                                <li>Nombre del documento enviado a firmar</li>
                                                <li>CRC del documento: identificador único (diferente para cada archivo)</li>
                                                <li>Localización: lugar en el que el documento fue firmado (incluye calle, número, ciudad, código postal, región y país)</li>
                                                <li>Fecha y hora de la firma</li>
                                            </ul>

                                            En el apartado “Evidencias del proceso”, se detallan los diferentes estados por los que ha pasado el documento desde que se envía a
                                            firmar:
                                            correo enviado, correo entregado, correo abierto, documento abierto y documento firmado.<br /><br />
                                            Para cada estado, se incluye la siguiente información: dirección IP, fecha y hora en la que cada estado del proceso ha ocurrido, tipo y
                                            versión de sistema operativo y tipo y versión de navegador. Las evidencias recogidas en los apartados “Información del envío” y
                                            “Evidencias
                                            del proceso” se pueden visualizar en el documento probatorio descargado en formato PDF. <br /><br />
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header-1" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo">
                                                <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                                    <h5>Autoridad de sellado de tiempo</h5>
                                                    <small className="text-muted">
                                                        <HelpCircle className="icon2" />
                                                    </small>
                                                </div>
                                            </button>
                                        </h2>
                                    </div>

                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div className="card-body-1 spacing">
                                            Una autoridad de sellado de tiempo es un prestador de servicios de certificación y actúa como tercero de confianza que proporciona
                                            servicios
                                            de sellado de tiempo para garantizar la fecha y hora de realización de cualquier operación o transacción por medios electrónicos. El
                                            sellado
                                            de tiempo es un método para probar que un conjunto de datos existió antes de un momento determinado, y que ninguno de estos datos ha
                                            sido
                                            modificado desde entonces.<br /><br />
                                            Cuando se realizan operaciones o transacciones por internet, conocer el tiempo en el que se producen es importante. Requerir los
                                            servicios
                                            de una Autoridad de Sellado de Tiempo que, como tercero de confianza, dará constancia de la fecha y hora en la que ocurrió la
                                            transacción es
                                            vital para aportar pruebas, garantizar la fuente de tiempo empleada y asegurar la integridad de los datos sellados.<br /><br />
                                            Para Tecalis, la seguridad de las experiencias en el mundo digital constituye una prioridad. Las prácticas de seguridad están
                                            profundamente
                                            integradas en nuestra cultura, así como en el desarrollo de nuestros software y en los procesos de operaciones de servicios.
                                            <br /><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 links">
                <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor">
                    <img src={"/img/home_signature_3.svg"} alt="" />
                </div>

                <div className="container">
                    <h4 align="center"><span className="badge rounded-pill bg-white-1 mt-5 py-2 pr-3 pl-3 spacing">Garantías</span></h4>

                    <div className="links-wrapper">
                        <div className="row links-card">
                            <div className="col-xl-12">
                                <div className="card hvr-float mb-3">
                                    <div className="card-body">
                                        <p className="font-size-md text-black-80 spacing mt-5 mb-4 pl-5 pr-5">
                                            👉 Garantía de privacidad e integridad
                                        </p>

                                        <p className="spacing mt-2 mb-4 pl-5 pr-5">
                                            Tecalis Signature garantiza la integridad de los datos que se introducen y transmiten a través de nuestra plataforma, garantizando la
                                            máxima
                                            privacidad e integridad en todos los documentos.
                                        </p>

                                        <p className="spacing mt-2 mb-4 pl-5 pr-5">
                                            Durante el proceso de firma, el sistema recopila numerosas evidencias electrónicas que ofrecen información adicional sobre el contexto
                                            en el
                                            que se ha realizado la firma. Dichas evidencias se incluyen con total integridad en el documento probatorio de firma. Por otro lado,
                                            Tecalis
                                            Signature garantiza la integridad de las evidencias electrónicas mediante un sello de tiempo oficial, garantizando que los datos del
                                            documento probatorio, así como la propia firma, no han sido alterados después de la firma.
                                        </p>

                                        <p className="spacing mt-2 mb-5 pl-5 pr-5">
                                            Tecalis Signature emplea un riguroso conjunto de actividades de seguridad específicas que abarcan procesos, herramientas y prácticas de
                                            desarrollo de software, todo ello integrado en las múltiples fases del ciclo de vida del producto. Ya sea en materia de gestión de la
                                            identidad, la confidencialidad de los datos o la integridad de los documentos, Tecalis Signature protege tus documentos, datos e
                                            información
                                            personal.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-12">
                                <div className="card hvr-float mb-3">
                                    <div className="card-body">
                                        <p className="font-size-md text-black-80 spacing mt-5 mb-4 pl-5 pr-5">
                                            👉 Garantía legal en todas las firmas
                                        </p>
                                        <p className="spacing mt-2 mb-4 pl-5 pr-5">
                                            El Reglamento relativo a la identificación electrónica y los servicios de confianza (eIDAS) convierte en legal y aplicable cualquier
                                            tipo de
                                            firma electrónica, ofreciendo una total seguridad jurídica para todas las transacciones digitales.
                                        </p>
                                        <p className="spacing mt-2 mb-4 pl-5 pr-5">
                                            Las firmas electrónicas realizadas en Tecalis Signature son legales, fiables y aplicables en la mayoría de los países de todo el mundo.
                                            Todos los datos que circulan a través de nuestra plataforma están encriptados y cifrados, cumpliendo con los estándares más rigurosos en
                                            seguridad. Además, nuestra solución de firma digital cumple tanto con la legislación europea como con la legislación de los EEUU.
                                        </p>
                                        <p className="spacing mt-2 mb-5 pl-5 pr-5">
                                            Además, nuestra solución genera un documento probatorio con sello de tiempo, emitido por una autoridad certificadora, que asegura que
                                            los
                                            documentos y las firmas no han sido alterados. Queda registrado el email (tanto del receptor como del remitente), el nombre del
                                            documento y
                                            el lugar y el momento exacto en que fue firmado. Estos documentos tienen plena validez legal ante un tribunal de justicia, amparándose
                                            en el
                                            derecho probatorio.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 cta">
                <div className="container">
                    <div className="row mt-3 mb-5">
                        <div className="col-xl-5">
                            <img src={"/img/unlock.svg"} width="450px" className="img-fluid mt-2 mb-5" />
                        </div>

                        <div className="col-xl-1" />

                        <div className="col-xl-6 check-list">
                            <h3 className="font-weight-bold spacing">
                                Cumplimiento en Protección de Datos
                            </h3>
                            <p className="font-size-md text-black-80 spacing mt-5 mb-2">
                                Tecalis Signature cumple con el Reglamento eIDAs y con el RGPD, debido a que nuestra solución de firma electrónica tiene las siguientes
                                características:
                            </p>

                            <br />

                            <ul className="spacing mb-5">
                                <li>Está vinculada al firmante de manera única</li>
                                <li>Permite la identificación del firmante</li>
                                <li>
                                    Se crea utilizando datos de creación de la firma electrónica que el firmante puede utilizar, con un alto nivel de confianza, bajo su control
                                    exclusivo
                                </li>
                                <li>Está vinculada con los datos firmados por la misma de modo tal, que cualquier modificación ulterior de los mismos es detectable</li>
                            </ul>

                            <p className="font-size-md text-black-80 spacing mb-2">
                                Además, durante el proceso de firma pedimos de forma expresa el consentimiento del firmante para capturar sus datos, tanto personales como
                                biométricos,
                                cumpliendo de forma estricta con las leyes vigentes en protección de datos.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
}

export default LegalityPage
